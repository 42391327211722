import { configureStore } from "@reduxjs/toolkit";
import LanguageReducer from "./features/LanguageSlice";
import ResponsiveReducer from "./features/ResponsiveSlice";
import GuideReducer from "./features/GuideSlice";

const store = configureStore({
  reducer: {
    guide: GuideReducer,
    language: LanguageReducer,
    responsive: ResponsiveReducer,
  },
});

export default store;
