import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateResponsiveState } from "../redux/features/ResponsiveSlice";

import logo2 from "../assets/logo/logo2.png";
import expand_icon from "../assets/icons/expand_icon.png";
import layout678 from "../assets/images/layout678.png";
import layout1018 from "../assets/images/layout1018.png";
import layout2023 from "../assets/images/layout2023.png";
import layout2433 from "../assets/images/layout2433.png";

const overview360List = [
  {
    id: 33,
    label: "Tầng 33",
    // frameUrl: "./file360/overview/index.html?startscene=scene_t33_d1_0005",
    layout: layout2433,
    subMenu: [
      {
        id: 1,
        label: "UN B - 33.05",
        frameUrl: "./file360/overview/index.html?startscene=scene_t33_d1_0005",
      },
      {
        id: 2,
        label: "UN B - 33.06",
        frameUrl: "./file360/overview/index.html?startscene=scene_t33_d2_0013",
      },
      {
        id: 3,
        label: "UN B - 33.03A",
        frameUrl: "./file360/overview/index.html?startscene=scene_t33_d3_0023",
      },
      {
        id: 4,
        label: "UN B - 33.03",
        frameUrl: "./file360/overview/index.html?startscene=scene_t33_d4_0042",
      },
      {
        id: 5,
        label: "UN B - 33.07",
        frameUrl: "./file360/overview/index.html?startscene=scene_t33_d5_0036",
      },
      {
        id: 6,
        label: "UN B - 33.01",
        frameUrl: "./file360/overview/index.html?startscene=scene_t33_d6_0039",
      },
    ],
  },
  {
    id: 30,
    label: "Tầng 30",
    layout: layout2433,
    subMenu: [
      {
        id: 1,
        label: "UN B - 30.05",
        frameUrl: "./file360/overview/index.html?startscene=scene_t30_d1",
      },
      {
        id: 2,
        label: "UN B - 30.06",
        frameUrl: "./file360/overview/index.html?startscene=scene_t30_d2",
      },
      {
        id: 3,
        label: "UN B - 30.03A",
        frameUrl: "./file360/overview/index.html?startscene=scene_t30_d3_0024",
      },
      {
        id: 4,
        label: "UN B - 30.03",
        frameUrl: "./file360/overview/index.html?startscene=scene_t30_d4_0043",
      },
      {
        id: 5,
        label: "UN B - 30.07",
        frameUrl: "./file360/overview/index.html?startscene=scene_t30_d5_0037",
      },
      {
        id: 6,
        label: "UN B - 30.01",
        frameUrl: "./file360/overview/index.html?startscene=scene_t30_d6_0040",
      },
    ],
  },
  {
    id: 25,
    label: "Tầng 25",
    // frameUrl: "./file360/overview/index.html?startscene=scene_t24_d1_0004",
    layout: layout2433,
    subMenu: [
      {
        id: 1,
        label: "UN B - 25.05",
        frameUrl: "./file360/overview/index.html?startscene=scene_t24_d1_0004",
      },
      {
        id: 2,
        label: "UN B - 25.06",
        frameUrl: "./file360/overview/index.html?startscene=scene_t24_d2_0012",
      },
      {
        id: 3,
        label: "UN B - 25.03A",
        frameUrl: "./file360/overview/index.html?startscene=scene_t24_d3_0025",
      },
      {
        id: 4,
        label: "UN B - 25.03",
        frameUrl: "./file360/overview/index.html?startscene=scene_t24_d4_0044",
      },
    ],
  },
  {
    id: 20,
    label: "Tầng 20",
    // frameUrl: "./file360/overview/index.html?startscene=scene_t20_d1_0006",
    layout: layout2023,
    subMenu: [
      {
        id: 1,
        label: "UN B - 20.05",
        frameUrl: "./file360/overview/index.html?startscene=scene_t20_d1_0006",
      },
      {
        id: 2,
        label: "UN B - 20.06",
        frameUrl: "./file360/overview/index.html?startscene=scene_t20_d2_0014",
      },
      {
        id: 3,
        label: "UN B - 20.03A",
        frameUrl: "./file360/overview/index.html?startscene=scene_t20_d3_0026",
      },
      {
        id: 4,
        label: "UN B - 20.03",
        frameUrl: "./file360/overview/index.html?startscene=scene_t20_d4_0045",
      },
    ],
  },
  {
    id: 18,
    label: "Tầng 18",
    // frameUrl: "./file360/overview/index.html?startscene=scene_t18_d1_0006",
    layout: layout1018,
    subMenu: [
      {
        id: 1,
        label: "UN B - 18.05",
        frameUrl: "./file360/overview/index.html?startscene=scene_t18_d1_0003",
      },
      {
        id: 2,
        label: "UN B - 18.06",
        frameUrl: "./file360/overview/index.html?startscene=scene_t18_d2_0011",
      },
      {
        id: 3,
        label: "UN B - 18.03A",
        frameUrl: "./file360/overview/index.html?startscene=scene_t18_d3_0027",
      },
      {
        id: 4,
        label: "UN B - 18.03",
        frameUrl: "./file360/overview/index.html?startscene=scene_t18_d4_0046",
      },
    ],
  },
  {
    id: 15,
    label: "Tầng 15",
    // frameUrl: "./file360/overview/index.html?startscene=scene_t15_d1_0008",
    layout: layout1018,
    subMenu: [
      {
        id: 1,
        label: "UN B - 15.05",
        frameUrl: "./file360/overview/index.html?startscene=scene_t15_d1_0008",
      },
      {
        id: 2,
        label: "UN B - 15.06",
        frameUrl: "./file360/overview/index.html?startscene=scene_t15_d2",
      },
      {
        id: 3,
        label: "UN B - 15.03A",
        frameUrl: "./file360/overview/index.html?startscene=scene_t15_d3_0028",
      },
      {
        id: 4,
        label: "UN B - 15.03",
        frameUrl: "./file360/overview/index.html?startscene=scene_t15_d4_0047",
      },
    ],
  },
  {
    id: 10,
    label: "Tầng 10",
    // frameUrl: "./file360/overview/index.html?startscene=scene_t10_d1_0002",
    layout: layout1018,
    subMenu: [
      {
        id: 1,
        label: "UN B - 10.05",
        frameUrl: "./file360/overview/index.html?startscene=scene_t10_d1_0002",
      },
      {
        id: 2,
        label: "UN B - 10.06",
        frameUrl: "./file360/overview/index.html?startscene=scene_t10_d2_0010",
      },
      {
        id: 3,
        label: "UN B - 10.03A",
        frameUrl: "./file360/overview/index.html?startscene=scene_t10_d3_0033",
      },
      {
        id: 4,
        label: "UN B - 10.03",
        frameUrl: "./file360/overview/index.html?startscene=scene_t10_d4_0049",
      },
    ],
  },
  {
    id: 8,
    label: "Tầng 8",
    // frameUrl: "./file360/overview/index.html?startscene=scene_t8_d1_0007",
    layout: layout678,
    subMenu: [
      {
        id: 1,
        label: "UN B - 8.05",
        frameUrl: "./file360/overview/index.html?startscene=scene_t8_d1_0007",
      },
      {
        id: 2,
        label: "UN B - 8.06",
        frameUrl: "./file360/overview/index.html?startscene=scene_t8_d2_0015",
      },
      // {
      //   id: 3,
      //   label: "UN B - 8.03A",
      //   frameUrl: "./file360/overview/index.html?startscene=scene_t8_d3_0031",
      // },
      // {
      //   id: 4,
      //   label: "UN B - 8.03",
      //   frameUrl: "./file360/overview/index.html?startscene=scene_t8_d4_0050",
      // },
    ],
  },
  {
    id: 6,
    label: "Tầng 6",
    layout: layout678,
    subMenu: [
      {
        id: 1,
        label: "UN B - 6.05",
        frameUrl: "./file360/overview/index.html?startscene=scene_t6_d1_0001",
      },
      {
        id: 2,
        label: "UN B - 6.06",
        frameUrl: "./file360/overview/index.html?startscene=scene_t6_d2_0009",
      },
      // {
      //   id: 3,
      //   label: "UN B - 6.03A",
      //   frameUrl: "./file360/overview/index.html?startscene=scene_t6_d3_0030",
      // },
      // {
      //   id: 4,
      //   label: "UN B - 6.03",
      //   frameUrl: "./file360/overview/index.html?startscene=scene_t6_d4_0051",
      // },
    ],
  },
];

const OverviewUtilityButtons = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error(`Failed to enter fullscreen mode: ${err.message}`);
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error(`Failed to exit fullscreen mode: ${err.message}`);
        });
    }
  };

  return (
    <div className="hidden absolute right-4 top-4 md:flex flex-col gap-2 z-10">
      {/* Mở/Tắt Toàn Màn Hình */}
      <button
        className={`w-10 h-10 border border-white/20 backdrop-blur-sm ${
          !isFullscreen ? "bg-white/20" : "bg-hover/20"
        }  flex justify-center items-center rounded-lg`}
        onClick={() => toggleFullscreen()}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {isFullscreen ? (
            <>
              <g clip-path="url(#clip0_473_5026)">
                <path
                  d="M16.5017 9.75767H23.2453C23.6709 9.75767 23.9886 9.42196 23.9886 8.99712C23.9886 8.57153 23.6709 8.25905 23.2453 8.2598H17.251V2.25343C17.251 1.82784 16.9273 1.50415 16.5017 1.50415C16.0761 1.50415 15.7524 1.82784 15.7524 2.25343V8.99712C15.7524 8.99936 15.7532 9.00085 15.7532 9.00235C15.7532 9.00459 15.7524 9.00608 15.7524 9.00833C15.7524 9.42644 16.0835 9.75841 16.5017 9.75767Z"
                  fill="#FFFEFE"
                />
                <path
                  d="M7.49222 1.51001C7.06738 1.51001 6.7549 1.82847 6.7549 2.25331V8.24766H0.749278C0.32369 8.24766 0 8.57135 0 8.99693C0 9.42252 0.32369 9.74621 0.749278 9.74621H7.49297C7.49521 9.74621 7.4967 9.74546 7.4982 9.74546C7.50044 9.74546 7.50193 9.74621 7.50418 9.74621C7.92229 9.74621 8.25345 9.41505 8.25345 8.99693V2.25331C8.25352 1.82847 7.91781 1.51076 7.49222 1.51001Z"
                  fill="#FFFEFE"
                />
                <path
                  d="M7.49815 14.2422H0.754529C0.328942 14.2429 0.0112305 14.5779 0.0112305 15.0035C0.0112305 15.4291 0.328942 15.7416 0.754529 15.7408H6.74888V21.7464C6.74888 22.172 7.07257 22.4957 7.49815 22.4957C7.92374 22.4957 8.24743 22.172 8.24743 21.7464V15.0027C8.24743 15.0005 8.24668 14.999 8.24668 14.9975C8.24668 14.9953 8.24743 14.9938 8.24743 14.9915C8.2475 14.5734 7.91627 14.2422 7.49815 14.2422Z"
                  fill="#FFFEFE"
                />
                <path
                  d="M23.2507 14.2422H16.5071C16.5048 14.2422 16.5033 14.2429 16.5018 14.2429C16.4996 14.2429 16.4981 14.2422 16.4959 14.2422C16.0777 14.2422 15.7466 14.5734 15.7466 14.9915V21.7352C15.7466 22.1607 16.0823 22.4777 16.5079 22.4785C16.9327 22.4792 17.2452 22.1607 17.2452 21.7359V15.7416H23.2507C23.6763 15.7416 24 15.4179 24 14.9923C24 14.5667 23.6763 14.2429 23.2507 14.2422Z"
                  fill="#FFFEFE"
                />
              </g>
              <defs>
                <clipPath id="clip0_473_5026">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </>
          ) : (
            <>
              <g clip-path="url(#clip0_473_5026)">
                <path
                  d="M16 3H22V9H20V5H16V3ZM2 3H8V5H4V9H2V3ZM20 19V15H22V21H16V19H20ZM4 19H8V21H2V15H4V19Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_473_5026">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </>
          )}
        </svg>
      </button>
    </div>
  );
};

const Overview360Menu = ({
  activeNumber,
  setActiveNumber,
  activeSubItemNumber,
  setActiveSubItemNumber,
  setCurrentScene,
  isMobile,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHoverID, setIsHoverID] = useState(0);

  const handleSetActiveNumber = (id, scene) => {
    setActiveNumber(id);
    setActiveSubItemNumber(1);

    if (id === 6) {
      setCurrentScene(
        "./file360/overview/index.html?startscene=scene_t6_d1_0001"
      );
    } else if (id === 8) {
      setCurrentScene(
        "./file360/overview/index.html?startscene=scene_t8_d1_0007"
      );
    } else if (id === 10) {
      setCurrentScene(
        "./file360/overview/index.html?startscene=scene_t10_d1_0002"
      );
    } else if (id === 15) {
      setCurrentScene(
        "./file360/overview/index.html?startscene=scene_t15_d1_0008"
      );
    } else if (id === 18) {
      setCurrentScene(
        "./file360/overview/index.html?startscene=scene_t18_d1_0003"
      );
    } else if (id === 20) {
      setCurrentScene(
        "./file360/overview/index.html?startscene=scene_t20_d1_0006"
      );
    } else if (id === 25) {
      setCurrentScene(
        "./file360/overview/index.html?startscene=scene_t24_d1_0004"
      );
    } else if (id === 30) {
      setCurrentScene("./file360/overview/index.html?startscene=scene_t30_d1");
    } else if (id === 33) {
      setCurrentScene(
        "./file360/overview/index.html?startscene=scene_t33_d1_0005"
      );
    } else {
      setCurrentScene(scene);
    }
  };

  const handleSetActiveSubItemNumber = (id, itemId, scene) => {
    setActiveNumber(id);
    setActiveSubItemNumber(itemId);
    setCurrentScene(scene);
  };

  return (
    <section className="absolute flex flex-col items-center gap-3 top-4 left-4 border border-white/20 rounded-lg w-24 md:w-32 h-auto bg-white/20 backdrop-blur-sm p-1 md:p-2">
      <img
        src={logo2}
        className="w-full h-full object-contain rounded-md"
        alt="the-opusk logo"
      />
      <img
        src={expand_icon}
        className={`w-6 h-6 md:w-8 md:h-8 cursor-pointer transition-all duration-300 ${
          isExpanded ? "rotate-180" : "rotate-0"
        }`}
        alt="expand_icon"
        onClick={() => setIsExpanded(!isExpanded)}
      />
      <ul
        className={`flex flex-col gap-1 md:gap-2 w-full transition-all duration-700 ease-in-out ${
          isExpanded ? "h-0 hidden" : "h-auto block"
        }`}
      >
        {overview360List.map((item, index) => (
          <li
            key={index}
            className={`relative flex justify-between items-center text-white text-xs md:text-sm text-center rounded-lg p-2 md:p-2 w-full cursor-pointer  ${
              activeNumber === item.id
                ? "bg-active"
                : "hover:bg-hover bg-primary"
            }`}
            onClick={() =>
              isMobile
                ? handleSetActiveNumber(item.id, item.frameUrl)
                : !item.subMenu && handleSetActiveNumber(item.id, item.frameUrl)
            }
            onMouseOver={() => setIsHoverID(item.id)}
            onMouseLeave={() => setIsHoverID(0)}
          >
            <p>{item.label}</p>

            {item?.subMenu && (
              <svg
                className="w-4 h-4 md:w-6 md:h-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.3999 18L15.3999 12L9.3999 6L7.9999 7.4L12.5999 12L7.9999 16.6L9.3999 18Z"
                  fill="white"
                />
              </svg>
            )}

            {item?.subMenu && isHoverID === item.id && (
              <ul
                className={`hidden absolute md:flex flex-col gap-2 left-32 top-0 border border-white/20 rounded-lg w-32 h-auto bg-white/20 backdrop-blur-sm p-2 before:absolute before:w-5 before:h-full before:top-0 before:-left-5 before:bg-transparent`}
              >
                {item?.subMenu?.map((subItem, indx) => (
                  <li
                    key={indx}
                    className={`flex justify-center items-center text-primary border-2 text-sm text-center rounded-lg p-2 w-full cursor-pointer ${
                      activeNumber === item.id &&
                      activeSubItemNumber === subItem.id
                        ? "border-primary bg-hover/80"
                        : "border-transparent bg-white hover:bg-hover/80 hover:text-white"
                    }`}
                    onClick={() =>
                      handleSetActiveSubItemNumber(
                        item.id,
                        subItem.id,
                        subItem.frameUrl
                      )
                    }
                  >
                    <p className="text-xs">{subItem.label}</p>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

const Overview360SubMenu = ({
  activeNumber,
  setActiveNumber,
  activeSubItemNumber,
  setActiveSubItemNumber,
  setCurrentScene,
}) => {
  const handleSetActiveSubItemNumber = (id, itemId, scene) => {
    setActiveNumber(id);
    setActiveSubItemNumber(itemId);
    setCurrentScene(scene);
  };

  return (
    <section
      className={`overflow-auto md:hidden absolute bottom-10 left-1/2 -translate-x-1/2 w-max max-w-[90vw] h-auto bg-white/20 backdrop-blur-sm border border-white/20 rounded-md`}
    >
      {overview360List.map(
        (item) =>
          item.id === activeNumber && (
            <ul className={`flex flex-row justify-start items-start p-1.5 gap-1.5 w-max h-full overflow-auto`}>
              {item?.subMenu?.map((subItem, indx) => (
                <li
                  key={indx}
                  className={`flex justify-center items-center text-primary border-4 text-xs text-center rounded-lg p-1 w-max cursor-pointer ${
                    activeNumber === item.id &&
                    activeSubItemNumber === subItem.id
                      ? "border-primary bg-hover/80"
                      : "border-transparent bg-white hover:bg-hover/80 hover:text-white"
                  }`}
                  onClick={() =>
                    handleSetActiveSubItemNumber(
                      item.id,
                      subItem.id,
                      subItem.frameUrl
                    )
                  }
                >
                  <p className="text-xs">{subItem.label}</p>
                </li>
              ))}
            </ul>
          )
      )}
    </section>
  );
};

const Overview360Page = () => {
  const dispatch = useDispatch();
  const responsiveState = useSelector((state) => state.responsive);
  const { isMobile } = responsiveState;
  const [activeNumber, setActiveNumber] = useState(6);
  const [activeSubItemNumber, setActiveSubItemNumber] = useState(1);
  const [currentScene, setCurrentScene] = useState(
    "./file360/overview/index.html?startscene=scene_t6_d1_0001"
  );

  const layoutSelected = overview360List.find(
    (item) => item.id === activeNumber
  );

  console.log("layoutSelected", layoutSelected);

  useEffect(() => {
    const handleResize = () => {
      dispatch(updateResponsiveState());
    };

    handleResize(); // Initial call to set the initial state

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
  // END RESPONSIVE

  return (
    <section className="absolute h-full w-full bg-primary">
      <Overview360Menu
        activeNumber={activeNumber}
        setActiveNumber={setActiveNumber}
        activeSubItemNumber={activeSubItemNumber}
        setActiveSubItemNumber={setActiveSubItemNumber}
        setCurrentScene={setCurrentScene}
        isMobile={isMobile}
      />

      <OverviewUtilityButtons />

      <Overview360SubMenu
        activeNumber={activeNumber}
        setActiveNumber={setActiveNumber}
        activeSubItemNumber={activeSubItemNumber}
        setActiveSubItemNumber={setActiveSubItemNumber}
        setCurrentScene={setCurrentScene}
      />

      <div className="hidden md:block absolute bottom-4 right-4 border border-white/20 bg-white/20 backdrop-blur-sm w-24 h-60 rounded-lg p-2">
        <img
          src={layoutSelected?.layout}
          alt="layout1"
          className="w-full h-full object-contain"
        />
      </div>

      <iframe
        src={currentScene}
        title="The OpusK Overview"
        className="w-full h-full"
      />
    </section>
  );
};

export default Overview360Page;
