import React from "react";
import mouse_icon from "../../assets/icons/mouse_icon.png";
import hand_icon from "../../assets/icons/hand_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { hideGuide } from "../../redux/features/GuideSlice";

const Guide = () => {
  const dispatch = useDispatch();
  const guide = useSelector((state) => state.guide.guide);

  return (
    <section
      className={`${
        guide ? "block" : "hidden"
      } w-full h-full z-[90] absolute top-0 left-0 bg-black/80 `}
    >
      <div className="w-[90%] md:w-[520px] h-[300px] md:h-[400px] xl:w-[580px] xl:h-[450px] p-5 md:p-8 xl:p-10 flex flex-col justify-center items-center gap-5 md:gap-10 bg-primary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg border border-white/20">
        <h1 className="text-2xl md:text-2xl xl:text-3xl text-white text-center uppercase">
          hướng dẫn thao tác
        </h1>
        <div className="flex w-full justify-center items-center gap-5">
          <img
            className="w-16 h-16 xl:w-20 xl:h-20 object-contain"
            src={hand_icon}
            alt="hand_icon"
          />
          <p className="text-white text-sm md:text-base">hoặc</p>
          <img
            className="w-16 h-16 xl:w-20 xl:h-20 object-contain"
            src={mouse_icon}
            alt="mouse_icon"
          />
        </div>
        <p className="text-white text-sm md:text-xl px-10 md:px-16 text-center">
          Chạm, di chuyển hoặc click vào từng vị trí tham quan để xem chi tiết!
        </p>
        <button
          className="bg-hover rounded-md w-max px-16 md:px-20 py-3 md:py-5 shadow-xl hover:shadow-slate-50/20 border border-white/20"
          onClick={() => dispatch(hideGuide())}
        >
          <p className="font-medium text-white text-sm md:text-base">Đã hiểu</p>
        </button>
      </div>
    </section>
  );
};

export default Guide;
