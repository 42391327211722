import React, { useEffect } from 'react'
import { Outlet, useLocation } from "react-router-dom";
import Guide from "../../components/Guide";
import GuideSVG from "../../components/Guide/GuideSVG";
import { useDispatch, useSelector } from "react-redux";
import { hideGuide, hideGuideSVG, showGuide, showGuideSVG } from "../../redux/features/GuideSlice";

const SharedLayout = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    // const planState = useSelector((state) => state.plan);
    // const { activeHouseSampleView } = planState;

    useEffect(() => {
        dispatch(showGuide());
      }, [dispatch]);

  return (
    <div className='SharedLayout_wrapper'>
        <Outlet />
        <Guide />
    </div>
  )
}

export default SharedLayout