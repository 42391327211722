import React, { Suspense } from 'react'
import "./App.css";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SharedLayout from './pages/SharedLayout';
import Overview360Page from './pages/HomePage';

const LoadingPage = React.lazy(() => import("./pages/LoadingPage"));
const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));

function App() {
  const languageState = useSelector((state) => state.language);

  const { locale, messages } = languageState;
  return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <Routes>
              <Route path="/" index element={<LoadingPage />} />
              <Route element={<SharedLayout />}>
                <Route index path="/overview" element={<Overview360Page />} />
              </Route>
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Router>
        </Suspense>
      </div>
  );
}

export default App;
