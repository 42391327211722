import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  guide: false,
  guideSVG: false,
};

const guideSlice = createSlice({
  name: "guide",
  initialState,
  reducers: {
    hideGuide: (state) => {
      state.guide = false;
    },
    showGuide: (state) => {
      state.guide = true;
    },
    hideGuideSVG: (state) => {
      state.guideSVG = false;
    },
    showGuideSVG: (state) => {
      state.guideSVG = true;
    },
  },
});

export default guideSlice.reducer;
export const { hideGuide, showGuide, hideGuideSVG, showGuideSVG } = guideSlice.actions;
